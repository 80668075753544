import { deleteRequest, get, post, put } from "../web.request";

export const quotationListData = async (query) => {
  return await get(`/quotationList${query}`);
}

export const quotationMailData = async (id) => {
  return await get(`/quotationMailData/${id}`);
}
export const sendMail = async (data) => {
  return await post(`/sendMail`,data);
}

export const addQuotation = async (data) => {
  return await post(`/addQuotation`, data);
}
