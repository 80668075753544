import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Notification from "./components/Common/Notification/Notification";
import { useDispatch, useSelector } from 'react-redux';
import ScrollToTop from "./helper/ScrollToTop";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import Loader from "./components/Common/Loader/Loader";
import { useLocation } from 'react-router-dom';
import { loadUser } from "./Service/auth.service";
import PrivateRoute from "./Routes/PrivateRoute";
import { loginUserApi, logoutApi } from "./state/Action/authAction";
import { Modal } from '../src/components/Common';
import WarehouseDetails from "./pages/Warehouse/WarehouseDetails";
import TransporterDetails from "./pages/Transporter/TransporterDetails";
import CompanyDetails from "./pages/Company/CompanyDetails";
import { Appointment } from "./pages/Appointment/Appointment.js";
import AppointmentDetails from "./pages/Appointment/AppointmentDetails.js";
import { useUserNotificationSubscribeHook } from "./Notification.js";
import DiscountAdd from "./pages/Coupons/sections/DiscountAdd.js";
import PurchaseBills from "./pages/PurchaseBills/PurchaseBills.js";
import PBillsDetails from "./pages/PurchaseBills/PBillsDetails.js";
import SInvoiceDetails from "./pages/SalesInvoice/SInvoiceDetails.js";
import SalesInvoice from "./pages/SalesInvoice/SalesInvoice.js";
import Brand from "./pages/Brand/index.js";
import { RaiseTicketPage } from "./pages/RaiseTicket/RaiseTicket";
import ContactUsList from "./pages/ContactUs/ContactUsList.js";

const Login = React.lazy(() => import('./pages/Login/Login'));
const Layout = React.lazy(() => import("./components/layouts/Layout"));
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const Product = React.lazy(() => import("./pages/Product/Product"));
const AddProduct = React.lazy(() => import("./pages/Product/AddProduct"));
const Category = React.lazy(() => import("./pages/Category/CategoryMain"));
const ProductDetails = React.lazy(() => import("./pages/Product/ProductDetails"));
const ProfileSettings = React.lazy(() => import("./pages/ProfileSettings/ProfileSettings"));
const UserManagement = React.lazy(() => import("./pages/AdminManagement/AdminManagement"));
const CreateRole = React.lazy(() => import("./pages/Role/sections/CreateRole"));
const CityList = React.lazy(() => import("./pages/Location/City/CityList"));
const StateList = React.lazy(() => import("./pages/Location/State/StateList"));
const CountryList = React.lazy(() => import("./pages/Location/Country/CountryList"));
const CouponMain = React.lazy(() => import("./pages/Coupons/CouponMain.js"));
const CouponAdd = React.lazy(() => import("./pages/Coupon/CouponAdd"));
const UserList = React.lazy(() => import("./pages/User/User"));
const UserDetail = React.lazy(() => import("./pages/User/UserDetails"));
const WarehouseList = React.lazy(() => import("./pages/Warehouse/Warehouse"));
const TransporterList = React.lazy(() => import("./pages/Transporter/Transporter"));
const WarehouseAdd = React.lazy(() => import("./pages/Warehouse/WarehouseAdd"));
const TransporterAdd = React.lazy(() => import("./pages/Transporter/TransporterAdd"));
const Company = React.lazy(() => import("./pages/Company/Company"));
const CompanyAdd = React.lazy(() => import("./pages/Company/CompayAdd"));
const PurchaseOrder = React.lazy(() => import("./pages/PurchaseOrder/PurchaseOrderList"));
const Quotation = React.lazy(() => import("./pages/Quotation/QuotationList.js"));
const Payment = React.lazy(() => import("./pages/Payment/PaymentList"));
const Inventory = React.lazy(() => import("./pages/Inventory/inventoryList"));
const POrderDetails = React.lazy(() => import("./pages/PurchaseOrder/POrderDetails.js"));
const QuotationDetails = React.lazy(() => import("./pages/Quotation/QuotationDetails.js"));
const POrderAdd = React.lazy(() => import("./pages/PurchaseOrder/POrderAdd.js"));
const QuotationAdd = React.lazy(() => import("./pages/Quotation/QuotationAdd.js"));
const SalesOrder = React.lazy(() => import("./pages/SalesOrder/SalesOrderList"));
const SOrderDetails = React.lazy(() => import("./pages/SalesOrder/SOrderDetails"));
const SalesCart = React.lazy(() => import("./pages/SalesCart/SalesCart"));
const SalesCartAdd = React.lazy(() => import("./pages/SalesCart/SalesCartAdd"));
const SalesCartDetails = React.lazy(() => import("./pages/SalesCart/SalesCartDetails"));
const RoleManagement = React.lazy(() => import("./pages/Role/RoleManagement.js"));
const Tax = React.lazy(() => import("./pages/Tax/TaxList.js"));
const UOM = React.lazy(() => import("./pages/uom/UomList.js"));
const Feature = React.lazy(() => import("./pages/feature/FeatureList.js"));
const RaiseTicketDetails = React.lazy(() => import("./pages/RaiseTicket/RaiseTicketDetails"))

const Slot = React.lazy(() => import("./pages/Slot/SlotList.js"));
const OfferAdd = React.lazy(() => import("./pages/SalesOffer/OfferAdd.js"));
const Offer = React.lazy(() => import("./pages/SalesOffer/Offer.js"));

const pages = [
  { name: 'dashboard', path: 'dashboard', element: <Dashboard /> },
  { name: 'product', path: 'product', element: <Product /> },
  { name: 'product', path: 'create-product', element: <AddProduct /> },
  { name: 'product', path: 'update-product', element: <AddProduct /> },
  { name: 'product', path: 'view-product', element: <ProductDetails /> },
  { name: 'profile', path: 'profile-settings', element: <ProfileSettings /> },
  { name: 'category', path: 'category', element: <Category /> },
  { name: 'admin', path: 'admins', element: <UserManagement /> },
  { name: 'role', path: 'create-role', element: <CreateRole /> },
  { name: 'role', path: 'update-role', element: <CreateRole /> },
  { name: 'city', path: 'city', element: <CityList /> },
  { name: 'state', path: 'state', element: <StateList /> },
  { name: 'country', path: 'country', element: <CountryList /> },
  { name: 'coupon', path: 'coupon', element: <CouponMain /> },
  { name: 'coupon', path: 'create-coupon', element: <CouponAdd /> },
  { name: 'coupon', path: 'create-discount', element: <DiscountAdd /> },
  { name: 'coupon', path: 'update-discount', element: <DiscountAdd /> },
  { name: 'coupon', path: 'update-coupon', element: <CouponAdd /> },
  { name: 'users', path: 'users', element: <UserList /> },
  { name: 'contactus', path: 'contactus', element: <ContactUsList /> },
  { name: 'users', path: 'view-user', element: <UserDetail /> },
  { name: 'warehouse', path: 'warehouse', element: <WarehouseList /> },
  { name: 'transporter', path: 'transporter', element: <TransporterList /> },
  { name: 'warehouse', path: 'create-warehouse', element: <WarehouseAdd /> },
  { name: 'transporter', path: 'create-transporter', element: <TransporterAdd /> },
  { name: 'warehouse', path: 'update-warehouse', element: <WarehouseAdd /> },
  { name: 'transporter', path: 'update-transporter', element: <TransporterAdd /> },
  { name: 'transporter', path: 'view-transporter', element: <TransporterDetails /> },
  { name: 'warehouse', path: 'view-warehouse', element: <WarehouseDetails /> },
  { name: 'company', path: 'company', element: <Company /> },
  { name: 'company', path: 'create-company', element: <CompanyAdd /> },
  { name: 'company', path: 'view-company', element: <CompanyDetails /> },
  { name: 'company', path: 'update-company', element: <CompanyAdd /> },
  { name: 'purchase-order', path: 'purchase-order', element: <PurchaseOrder /> },
  { name: 'quotation', path: 'quotation', element: <Quotation /> },
  { name: 'purchase-order', path: 'view-purchase-order', element: <POrderDetails /> },
  { name: 'quotation', path: 'view-quotation', element: <QuotationDetails /> },
  { name: 'purchase-order', path: 'create-purchase-order', element: <POrderAdd /> },
  { name: 'quotation', path: 'create-quotation', element: <QuotationAdd /> },
  { name: 'quotation', path: 'update-quotation', element: <QuotationAdd /> },
  { name: 'purchase-order', path: 'update-purchase-order', element: <POrderAdd /> },
  { name: 'payment', path: 'sales-payment', element: <Payment /> },
  { name: 'inventory', path: 'inventory', element: <Inventory /> },
  { name: 'sales-order', path: 'sales-order', element: <SalesOrder /> },
  { name: 'sales-order', path: 'view-sales-order', element: <SOrderDetails /> },
  { name: 'SalesCart', path: 'sales-cart', element: <SalesCart /> },
  { name: 'sales-cart', path: 'create-sales-cart', element: <SalesCartAdd /> },
  { name: 'sales-cart', path: 'update-sales-cart', element: <SalesCartAdd /> },
  { name: 'sales-cart', path: 'view-sales-cart', element: <SalesCartDetails /> },
  { name: 'appointment', path: 'appointment', element: <Appointment /> },
  { name: 'appointment', path: 'view-appointment', element: <AppointmentDetails /> },
  { name: 'role', path: 'role', element: <RoleManagement /> },
  { name: 'tax', path: 'tax', element: <Tax /> },
  { name: 'slot', path: 'slot', element: <Slot /> },
  { name: 'purchase-bill', path: 'purchase-bills', element: <PurchaseBills /> },
  { name: 'purchase-bill', path: 'view-purchase-bill', element: <PBillsDetails /> },
  { name: 'sales-invoice', path: 'sales-invoice', element: <SalesInvoice /> },
  { name: 'sales-invoice', path: 'view-sales-invoice', element: <SInvoiceDetails /> },
  { name: 'brand', path: 'brand', element: <Brand /> },
  { name: 'uom', path: 'uom', element: <UOM /> },
  { name: 'feature', path: 'feature', element: <Feature /> },
  { name: 'raise-ticket', path: 'raise-ticket', element: <RaiseTicketPage/> },
  { name: 'view-ticket', path: 'view-ticket', element: <RaiseTicketDetails/> },
  { name: 'offer', path: 'sales-offer', element: <Offer/> },
  { name: 'offer', path: 'create-offer', element: <OfferAdd/> },
  { name: 'offer', path: 'update-offer', element: <OfferAdd/> },

]

function App() {
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const { registerAndSubscribe } = useUserNotificationSubscribeHook();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });
  const [roleName, setRoleName] = useState("admin");

  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);

  useEffect(() => {
    if (pathname == "/" && user && user?.role && user?.role) {
      Navigate("/dashboard");
    }
    setRoleName(user && user?.role);
  }, [user, pathname]);

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());

    Navigate('/');
  }

  useEffect(() => {
    if (auth && auth?.isExpired) {
      setIsModalOpen({
        open: true,
        currentComponent: "logout",
        para: 'Logged out due to inactivity. Please log in again to continue.',
        head: 'Session Expired',
      });
    }
  }, [auth]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      registerAndSubscribe(res?.data && res?.data?.id);
      dispatch(loginUserApi(res?.data));
    }
  };


  useEffect(() => {
    if (pathname !== "/") {
      const token = localStorage.getItem("token");
      const payload = {
        token: token,
      };
      getUsetData(payload);
    }

  }, []);


  useEffect(() => {
    if (show && show.show) {
      setShowNotification(show);
    } 
  }, [show]);


  return (
    <>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <div className="background_color">
            <Suspense fallback={<Loader />} >
              <Routes>
                <Route path="/" element={<Login />} />

                {pages?.map((page, index) => {
                  const { name, path, element } = page;
                  const permission =
                    user &&
                    user.permission &&
                    user.permission.find((permission) => {
                      return permission.pageName == name;
                    });

                  if (name == 'profile') {
                    return (
                      <Route key={index} path={`/${path}`} element={
                        <PrivateRoute>
                          <Layout>{element}</Layout>
                        </PrivateRoute>
                      }
                      />
                    )
                  }


                  else if ((permission && permission.readP === 1) || user.role == 1) {
                    return (
                      <Route key={index} exact path={`/${path}`} name={name} element={
                        <PrivateRoute>
                          <Layout>{element}</Layout>
                        </PrivateRoute>
                      }
                      />
                    )
                  }
                  else if (permission && permission.readP && permission.readP == 2 || permission && permission.readP == 0) {
                    return (
                      <Route key={index} path={path} element={<h1>Not Found</h1>} />
                    );
                  }

                })}
              </Routes >
            </Suspense>
          </div>
          {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}
          <Modal
            modalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            handleClose={() => {
              return;
            }}
            handelClick={handelLogout}
          />
        </ThemeProvider>
      </ScrollToTop>

    </>

  );
}

export default App;
