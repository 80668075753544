import React, { useEffect, useState } from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, FileUpload, Input, Autocomplete } from "../../components/Common";
import { CompanyDropdown } from '../../Service/Company.service';

const AddBrand = ({ handleClose, modalOpen, formik, files, setFiles }) => {
  const isEdit = modalOpen?.isEdit;
  const isView = modalOpen?.isView;
  const [vendorData, setVendorData] = useState([]);
  const getVendorData = async () => {
    const res = await CompanyDropdown();
    if (res?.status) {
      let data = res.data.map((item) => {
        return {
          id: item.id,
          name: item.companyName
        }
      }
      )
      setVendorData(data);
    } else {
      setVendorData([]);
    }
  }


   useEffect(() => {
      getVendorData();
    }, []);
  

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {isEdit ? "Edit" : isView ? "View" : "Add"} Brand
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: "500px" }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput="Name"
              disabled={isView}
              formik={formik}
              name="categoryName"
              maxLength={50}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "13px", sm: "16px" }}
              fontWeight={500}
              mb={"2px"}
            >
              Image
            </Typography>
            <FileUpload files={files} setFiles={setFiles} singleImage={true} />
          </Grid>
          <Grid item xs={12}>
              <Autocomplete
                                data={vendorData}
                                variant='standard'
                                labelinput='Company'
                                onChange={(e, val) => {
                                  if (val) {
                                    formik.setFieldValue('companyId', val.id);
                                    formik.touched.product = false;
                                  } else {
                                    formik.setFieldValue('companyId', '');
                                  }
                                }}
                                name='companyId'
                                name1='company'
                                formik={formik}
                                value={formik.values.company}
                              />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {isView ? (
          ""
        ) : (
          <>
            <Button
              buttonName="Cancel"
              size="small"
              color="white"
              onClick={handleClose}
            />
            <Button
              buttonName={`${isEdit ? "Save" : "Add"}`}
              size="small"
              onClick={formik.handleSubmit}
            />
          </>
        )}
      </DialogActions>
    </>
  );
};

export default AddBrand;
