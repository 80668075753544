import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  Alert,
  Box,
  Typography,
  Paper,
  Divider,
  styled,
  Stack,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "../../../components/Common";
import html2pdf from "html2pdf.js";
import {
  quotationMailData,
  sendMail,
} from "../../../Service/Quotaation.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../../assets/images/Logo.png";
import moment from "moment";

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    maxWidth: "600px",
    width: "100%",
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  position: "relative",
  "& .MuiIconButton-root": {
    color: theme.palette.common.white,
  },
}));

const PreviewSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
  borderRadius: "8px",
}));

const ProcessingOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  borderRadius: "12px",
}));

const validationSchema = Yup.object({
  emails: Yup.array()
    .of(
      Yup.string()
        .email("Invalid email address")
        .required("Email is required")
    )
    .min(1, "At least one email is required")
});

const MailModal = ({ handleClose, modalOpen, isLoading }) => {
  const [productList, setProductList] = useState([]);
  const [sendStatus, setSendStatus] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const getProductList = useCallback(async () => {
    try {
      const res = await quotationMailData(modalOpen.id);
      if (res?.status) {
        setProductList(res?.data);
      } else {
        setProductList([]);
        setError("Failed to load purchase order data");
      }
    } catch (err) {
      setError("Error loading purchase order data");
      console.error(err);
    }
  }, [modalOpen.id]);

  useEffect(() => {
    if (modalOpen?.id) {
      getProductList();
    }
  }, [getProductList]);

  const generateEmailContent = () => {
    const purchaseOrder = productList?.quotationData;
    const company = productList?.user;
    const products = productList?.productGroup;
    const purchaseOrderProductData = productList?.quotationProductData;
    const termsAndConditions = Array.isArray(purchaseOrder?.termsAndConditions)
    ? purchaseOrder.termsAndConditions
    : JSON.parse(purchaseOrder?.termsAndConditions || '[]');
    return `<!DOCTYPE html>
                           <html>
                   
                           <head>
                           <link href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap" rel="stylesheet">
                   
                             <style>
                             @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
                body {
                        font-family: Arial, sans-serif;
            
                        font-size: 12px;
                    }
                 
                 .container {
                   display: flex;
                   justify-content: space-between;
                   align-items: center;
                   margin: 20px;
                 }
                 
                 .financial-details {
                   text-align: left;
                   flex: 1;
                 }
                 
                 .total-amount {
                   text-align: right;
                   flex: 1;
                 }
                 
                 .top-header {
                   display: flex;
                   justify-content: space-between;
                   align-items: center;
                   padding: 15px;
                   margin-bottom: 0;
                   background: white;
                   border-bottom: 2px solid #7345b3;
                 }
                 
                 .top-header > div {
                   display: flex;
                   align-items: center;
                   gap: 15px;
                 }
                 
                 .logo {
                   width: 100px;
                   height: auto;
                   object-fit: contain;
                 }
                 
                 .top-header > div > div {
                   display: flex;
                   flex-direction: column;
                 }
                 
                 .company-name {
                   margin: 0;
                   font-size: 16px;
                   font-weight: bold;
                 }
                 
                 .company-location {
                   margin: 5px 0 0 0;
                   font-size: 14px;
                 }
                 
                 .approved-text {
                   color: #347c00;
                   font-weight: bold;
                 }
                 
                 .dark-header {
                   background: #7345b3;
                   color: white;
                   padding: 14px 15px;
                   display: flex;
                   font-weight: bold;
                   font-size: 15px;
                   justify-content: space-between;
                   align-items: center;
                   margin-bottom: 20px;
                 }
                 
                 .details-grid {
                   display: grid;
                   grid-template-columns: repeat(3, 1fr);
                   gap: 10px;
                   margin-bottom: 20px;
                 }
                 
                 .details-section {
                   font-size: 14px;
                 }
                 
                 .details-section h3 {
                   font-size: 12px;
                   font-weight: 800;
                   margin: 0 0 10px 0;
                 }
                 
                 .details-section h4 {
                   font-size: 12px;
                   font-weight: unset;
                   margin: 0px;
                 }
                 
                 .details-section p {
                   margin: 5px 0;
                   line-height: 12px;
                   color: rgb(59, 59, 59) ;
                   font-size: 12px;
                  
                 }
                 table {
                   width: 100%;
                   border-collapse: collapse;
                   margin: 20px 0 0px 0px;
                   font-size: 14px;
                 }
                 
                 th, td {
                   border: 1px solid #e1d7ef;
                   padding: 8px;
                   font-size: 10px;
                   line-height: 10px;
                   text-align: left;
                 }
                 
                 th {
                   color: #7345b3;
                   font-size: 10px;
                   background-color: #fcfaff;
                 }
                 
                 .box {
                   padding: 10px;
                   border-bottom: 1px solid #e1d7ef  ;
                 }
                 
                 .box h3 {
                   margin: 0px;
                   font-size: 14px;
                   color: #7345b3;
                 }
                 
                 .financial-details {
                   width: 300px;
                   margin-left: auto;
                   font-size: 14px;
                 }
                 
                 .financial-row {
                   display: flex;
                   justify-content: space-between;
                   padding: 5px 0;
                   color: #7345b3;
                 }
                 
                 .payment-terms {
                   margin: 20px 0;
                   font-size: 14px;
                 }
                 
                 .terms-conditions {
                   font-size: 10px;
                   margin: 20px 0;
                 }
                 
                 .terms-conditions h3 {
                   font-size: 11px;
                   font-weight: 800;
                   
                 }
                 
                 .terms-conditions p {
                 
                 }
                 
                 .signature-section {
                   margin-top: 20px;
                   font-size: 14px;
                  
                 }
                 
                 .signature-box {
                   border: 1px solid black;
                   width: 150px;
                   height: 30px;
                   margin-top: 10px;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   font-size: 14px;
                   overflow: hidden;
                 }
                 
                 .signature-box p {
                   color: black;
                   letter-spacing: 0.5px;
                   font-family: 'Dancing Script', 'Brush Script MT', cursive;
                   font-weight: 500;
                 }
                 
                 .signature-section h4 {
                   font-size: 14px;
                   font-family: Arial, sans-serif;
                   margin-bottom: 5px;
                 };
                       
                     </style>
                           </head>
                           <body>
                               <div class="top-header">
                                   <div>
                                   <img src=${Logo} class='logo' width="100" alt="Company Logo" />
                                   <div>
                                       <h1 class="company-name">CSD Trading Corporation Pvt. Ltd</h1>
                                   </div>
                                   </div>
                                   <span class="approved-text">APPROVED</span>
                               </div>
                       
                               <div class="dark-header">
                                   <span>Quotation No. : ${purchaseOrder?.orderNumber}</span>
                                   <span>Created on: ${moment(purchaseOrder?.createdAt).format(
                                     "DD MMM, YYYY"
                                   )}</span>
                               </div>
                       
                               <div class="details-grid">
                                   <div class="details-section">
                                       <h3>User Details</h3>
                                       <h4>${company?.userData?.firstName+" "+ company?.userData?.lastName}</h4>
                                       <p>${company?.userAdd?.address1}, ${company?.userAdd?.address2}</p>
                                       <p>${company?.userData?.email}</p>
                                       <p>GST: ${company?.userData?.gstNo}</p>
                                   </div>
                       
                                   <div class="details-section">
                                       <h3>Billing Details</h3>
                                       <p>${purchaseOrder?.billingAddress}</p>
                                       
                                       <p>GST: ${purchaseOrder?.gstNumber}</p>
                                   </div>
                               </div>
                               <table>
                                   <thead>
                                       <tr>
                                           <th>Material</th>
                                           <th>Ordered Quantity</th>
                                           <th>Unit Price(₹)</th>
                                           <th>Credit Amount(₹)</th>
                                           <th>Amount(₹) (after credit)</th>
                                           <th>CGST/SGST(%)</th>
                                           <th>Total(₹)</th>
                                       </tr>
                                   </thead>
                                   <tbody>
                                       ${purchaseOrderProductData?.map((product) => {
                                           const productDetails =
                                             products[product.productVariantId];
                                           const unitPrice = parseFloat(product.perProductPrice);
                                           const orderedQuantity = product.quantity;
                                        
                                           const amountAfterDiscount =
                                             unitPrice * orderedQuantity ;
                                           const tax = product.tax;
                                           const taxValue = product.taxValue; 
                                           const total = amountAfterDiscount + parseFloat(tax);
                   
                                           return `
                                               <tr>
                                                   <td>${productDetails?.productName}</td>
                                                   <td>${orderedQuantity}</td>
                                                   <td>${unitPrice}</td>
                                                    <td>${purchaseOrderProductData.creditAmount || 0}(${purchaseOrderProductData.creditDay||0} Days)</td>
                                                   <td>${amountAfterDiscount.toFixed(2)}</td>
                                                   <td>${tax}(${taxValue}%)</td>
                                                   <td>${total.toFixed(2)}</td>
                                               </tr>
                                           `;
                                         })
                                         .join("")}
                                       <tr>
                                           <td colspan="4"><strong>Net Amounts</strong></td>
                                           <td>${purchaseOrder?.subtotal}</td>
                                           <td>${purchaseOrder?.tax}</td>
                                           <td>${purchaseOrder?.payableAmount}</td>
                                       </tr>
                                   </tbody>
                               </table>
                       <div class="box">
                     <div class="container">
                           <h3 class="financial-details">Financial Details</h3>
                           <h3 class="total-amount">Total Amount</h3>
                       </div>
                               <div class="financial-details">
                                   <div class="financial-row">
                                       <span>Subtotal</span>
                                       <span>₹ ${purchaseOrder?.subtotal}</span>
                                   </div>
                                   <div class="financial-row">
                                       <span>Total Credit Amount</span>
                                       <span>(+) ₹ ${ purchaseOrderProductData?.reduce(
        (sum, product) => sum + (parseFloat(product.creditAmount) || 0), 0
    )}</span>
                                   </div>
                                   <div class="financial-row">
                                       <span>Total Amount(after credit)</span>
                                       <span>₹ ${
                                         purchaseOrder?.subtotal 
                                       }</span>
                                   </div>
                     
                                   <div class="financial-row">
                                       <span>Total Tax</span>
                                       <span>(+) ₹ ${purchaseOrder?.tax}</span>
                                   </div>
                                   <div class="financial-row">
                                       <span>Total (Incl. Taxes)</span>
                                       <span>₹ ${purchaseOrder?.payableAmount}</span>
                                   </div>
                                   <div class="financial-row">
                                       <span>Payable Amount</span>
                                       <span>₹ ${purchaseOrder?.payableAmount}</span>
                                   </div>
                                   <div class="financial-row">
                                       <span>Round off Total (Incl. Taxes)</span>
                                       <span>₹ ${purchaseOrder?.payableAmount}</span>
                                   </div>
                               </div>
                     
                               </div>
                       
                               <div class="terms-conditions">
                                   <h3>Terms & Conditions</h3>
                                   <ul>
                                   
                         ${termsAndConditions?.map((term) => `
                             <li>${term}</li>
                         `).join('') || '<li>No terms and conditions available</li>'}
                     </ul>
                               </div>
                       <div class="signature-section">
                       <p><b>Approved by</b></p>
                       <p>System Generated<br><b>${moment().format("MMM DD, YYYY; h:mm A")}</b></p>
                       <h4>Signature</h4>
                      <div class="signature-box"><p>Rohit Saha</p></div>
                     </div>
                              
                           </body>
                           </html>
           `;
  };


  const formik = useFormik({
    initialValues: {
      emails: [productList?.user?.userData?.email || ""]
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setProcessing(true);
      setSendStatus(null);
      setError(null);

      try {
        const emailContent = generateEmailContent();
        const pdfBlob = await html2pdf()
          .set({
            margin: 10,
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 4, letterRendering: true },
            jsPDF: { unit: "mm", format: "A4", orientation: "portrait" }
          })
          .from(emailContent)
          .output("blob");

        const formData = new FormData();
        formData.append(
          "file",
          pdfBlob,
          `Quotation__${productList?.quotationData?.orderNumber}.pdf`
        );
        
        // Append multiple emails
        values.emails.forEach((email, index) => {
          formData.append(`emails[${index}]`, email);
        });

        const response = await sendMail(formData);

        if (response.status) {
          setSendStatus("success");
          setTimeout(handleClose, 2000);
        } else {
          throw new Error(response.message || "Failed to send email");
        }
      } catch (error) {
        setSendStatus("error");
        setError(error.message || "Failed to send email");
      } finally {
        setProcessing(false);
      }
    }
  });

    const addEmailField = () => {
    formik.setFieldValue('emails', [...formik.values.emails, '']);
  };

  // Remove email input field
  const removeEmailField = (index) => {
    const newEmails = formik.values.emails.filter((_, i) => i !== index);
    formik.setFieldValue('emails', newEmails);
  };


  const orderNumber = productList?.quotationData?.orderNumber;


  return (
    <StyledDialog
    open={true}
    onClose={processing || downloading ? undefined : handleClose}
    maxWidth="md"
    fullWidth
  >
    {(processing || downloading) && (
      <ProcessingOverlay>
        <CircularProgress size={48} />
        <Typography variant="h6" sx={{ mt: 2, color: "primary.main" }}>
          {processing ? "Sending Quotation..." : "Downloading Quotation..."}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Please wait while we process your request
        </Typography>
      </ProcessingOverlay>
    )}

    <StyledDialogTitle>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">Send Quotation Email</Typography>
      </Box>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        disabled={processing || downloading}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </StyledDialogTitle>

    <DialogContent sx={{ mt: 3 }}>
      {error && (
        <Alert
          severity="error"
          sx={{ mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}

      <Grid container spacing={2}>
        {formik.values.emails.map((email, index) => (
          <Grid item xs={12} key={index} container alignItems="center" spacing={1}>
            <Grid item xs={index === 0 ? 12 : 10}>
              <Input
                labelinput={`Email Address ${index + 1}`}
                name={`emails[${index}]`}
                value={email}
                onChange={formik.handleChange}
                error={formik.touched.emails?.[index] && Boolean(formik.errors.emails?.[index])}
                helperText={formik.touched.emails?.[index] && formik.errors.emails?.[index]}
                placeholder="Enter recipient email address"
                disabled={processing || downloading}
                fullWidth
              />
            </Grid>
            {index > 0 && (
              <Grid item xs={2}>
                <IconButton 
                  onClick={() => removeEmailField(index)}
                  disabled={processing || downloading}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            buttonName="Add Another Email"
             color='white'
            onClick={addEmailField}
            disabled={processing || downloading}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <PreviewSection elevation={0}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Document Preview
            </Typography>
            <Divider sx={{ my: 1 }} />

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Quotation No. : {orderNumber}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Date: {moment().format("MMMM DD, YYYY")}
              </Typography>
            </Box>

            {sendStatus === "success" && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Email sent successfully!
              </Alert>
            )}
          </PreviewSection>
        </Grid>
      </Grid>
    </DialogContent>

    <DialogActions sx={{ p: 2.5, backgroundColor: "grey.50" }}>
      <Box sx={{ flex: 1 }} />
      <Button
        buttonName="Cancel"
        size="medium"
        color='white'
        onClick={handleClose}
        disabled={processing || downloading}
      />
      <Button
        buttonName={processing ? "Sending..." : "Send Email"}
        size="medium"
        variant="contained"
        onClick={formik.handleSubmit}
        disabled={processing || downloading || isLoading || !formik.isValid}
        startIcon={processing && <CircularProgress size={16} color="inherit" />}
      />
    </DialogActions>
  </StyledDialog>
  );
};

export default MailModal;
